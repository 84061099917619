<template>
    <div>
         <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>内容管理</el-breadcrumb-item>
            <el-breadcrumb-item>小视频</el-breadcrumb-item>
        </el-breadcrumb>
        <el-table
            :data="tabledete"
            border
            style="width: 100%">
            <el-table-column
            prop="date"
            label="日期"
            width="180">
            </el-table-column>
            <el-table-column
            prop="name"
            label="姓名"
            width="180">
            </el-table-column>
            <el-table-column
            prop="address"
            label="地址">
            </el-table-column>
        </el-table>
       <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageindex"
            :page-sizes="[10,20,50,80]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="100">
            </el-pagination>
    </div>
</template>

<script>
export default {

    data(){
        return{
            tabledete:[],//分页数据
            total:0,//总共几条
            pageindex:1,//当前第几页
            pagesize:20,//每页多少条
        }
    },
    mounted:function(){
    const that =this;//定义
      this.getdata()
    },
    methods:{
       handleSizeChange:function (e) {//改变每页几条的数据监听方法
           
       },
       handleCurrentChange:function (e) {//切换第几页的监听方法
           
       },
       getdata(){
           this.tableData=[];
           this.axios.get("/xyvideo/get_gu_xy_videos?page=" + this.pageindex + "&size=" + this.pagesize)
                    .then(rs => {
                         console.log(rs);
                        if(rs.data.code === '0'){
                            this.tableData = rs.data.result;
                        // that.tableData = res.result || [];//数据列表
                        // that.total = res.count; //总条数
                        // that.tabledata = rs.data.result || [];//数据列表
                        // that.total = rs.data.count; //总条数
                        }

                                           
                    })
        //    this.axios.post("/video/get_gu_xy_videos?page=" + this.pageindex + "&size=" + this.pagesize)
        //    .then(function(res)
        //    {
        //         // console.log(res);
        //         this.axios;
              
           
        //    })
           
       }
    }
    
}       
</script>

<style lang="less" scoped>

</style>